export const CACHE_DURATION = 12 * 60 * 60 * 1000; // 12 hours in milliseconds
export const TOKEN_CACHE_KEY = 'authToken';
export const COMPANIES_CACHE_KEY = 'companies';
export const TIMESTAMP_CACHE_KEY = 'cacheTimestamp';
export const ADMIN_CACHE_KEY = 'isAdmin';


// Save user data to local storage
export const saveUserData = (token: string, isAdmin: boolean) => {
  const currentTime = Date.now();
  localStorage.setItem(TOKEN_CACHE_KEY, token);
  localStorage.setItem(ADMIN_CACHE_KEY, JSON.stringify(isAdmin));
  localStorage.setItem(TIMESTAMP_CACHE_KEY, currentTime.toString());
};

// Load user data from local storage
export const loadUserData = () => {
  const currentTime = Date.now();
  const token = localStorage.getItem(TOKEN_CACHE_KEY);
  const cacheTimestamp = localStorage.getItem(TIMESTAMP_CACHE_KEY);
  const isAdmin = JSON.parse(localStorage.getItem(ADMIN_CACHE_KEY) || 'false');
  const validCachedToken = token && cacheTimestamp && (currentTime - parseInt(cacheTimestamp)) < CACHE_DURATION;

  return { token, isAdmin, validCachedToken };
};

// Save company data to local storage
export const saveCompanyData = (newCompanies: any) => {
    localStorage.setItem(COMPANIES_CACHE_KEY, JSON.stringify(newCompanies));
};

// Load company data from local storage
export const loadCompanyData = () => {
    const companies = localStorage.getItem(COMPANIES_CACHE_KEY);
    const cacheTimestamp = localStorage.getItem(TIMESTAMP_CACHE_KEY);
  
    return { companies, cacheTimestamp };
  };

// Clear all data from local storage
export const clearAllCachedData = () => {
  localStorage.removeItem(TOKEN_CACHE_KEY);
  localStorage.removeItem(ADMIN_CACHE_KEY);
  localStorage.removeItem(COMPANIES_CACHE_KEY);
  localStorage.removeItem(TIMESTAMP_CACHE_KEY);
};
