import React, { useState, useContext } from 'react';
import './About.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import logo from './illustration.png';
import Navbar from './Navbar';

interface AboutProps {
  loading: boolean; // Add this prop
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const About: React.FC<AboutProps> = ({ loading, setLoading }) => {
  const navigate = useNavigate();


  return (
    <div className="about-wrapper">
      <div className="navbar-admin-wrapper">
        <Navbar/>
      </div>
      <div className='horizontal-about-section'>
        <section className="about-hero">
          <div className="hero-text">
            <h2>Revolutionizing Financial Modeling</h2>
            <p>
              Monarq is a powerful platform designed to streamline the creation of financial models in Excel, enabling financial analysts to work faster and more efficiently.
            </p>
          </div>
        </section>
        <section className="about-content">
          <div className="content-block">
            <h3>Streamlined Workflows</h3>
            <p>
              By seamlessly integrating trusted third-party data from Visible Alpha, Monarq eliminates the need for manual data entry and ensures your models are always updated with the latest, most accurate information.
            </p>
          </div>
          <div className="content-block">
            <h3>Enhanced Efficiency</h3>
            <p>
              With Monarq, analysts can quickly access and incorporate relevant data into their financial models, customize key revenue parameters, and reduce the time spent on building and updating complex models.
            </p>
          </div>
          <div className="content-block">
            <h3>Drive Better Outcomes</h3>
            <p>
              Whether you’re working with simple forecasts or sophisticated multi-sheet models, Monarq makes it easier to access and manipulate the data you need to drive better business outcomes.
            </p>
          </div>
        </section>
      </div>
      {/* Key Features Section */}
      <div className='horizontal-about-section'>
        <section className="about-faqs">
          <h3>Key Features & Functionality</h3><br></br>
          <ul>
            <li><strong>Efficient Model Building</strong></li> <p>In just a few clicks, build a fully functional financial model in seconds—no complex setup, no manual data entry, and no overhead. Monarq streamlines the process, allowing you to create comprehensive models quickly and effortlessly.</p>
            <li><strong>Integration with Visible Alpha</strong></li><p> Access real-time, high-quality financial data from Visible Alpha, including company-specific revenue parameters, consensus forecasts, and other key metrics—all integrated directly into your Excel models.</p>
            <li><strong>Customizable Revenue Parameters</strong></li><p> Tailor your financial models with company-specific custom revenue parameters ensuring that forecasts reflect your unique analysis.</p>
            <li><strong>Faster Model Creation</strong></li><p> Build and update financial models in a fraction of the time, using the latest, most accurate data. Monarq makes it easy to create fully functional models quickly, allowing analysts to focus on analysis and insights rather than manual processes.</p>
            <li><strong>Flexibility</strong></li><p> Monarq allows analysts to quickly generate and customize financial models, making it easy to explore new business opportunities, scenarios, and "what-if" analyses with minimal setup time.</p>
            <li><strong>Reduced Manual Work</strong></li><p> Minimize manual effort and reduce human error by automatically integrating external data and model updates, saving you valuable time.</p>
            <li><strong>Industry-Standard Excel Model</strong></li><p> Monarq builds models using a proven, industry-standard Excel model template, ensuring your financial models follow best practices and are structured for consistency, accuracy, and ease of use.</p>
          </ul>
        </section>
      </div>

      {/* FAQ Section */}
      <div className='horizontal-about-section'>
        <section className="about-faqs">
          <h3>FAQs for Monarq</h3>
          <div className="faq-item">
            <h4>1. What is Monarq and how does it help financial analysts?</h4>
            <p>
              Monarq is a financial modeling platform that simplifies and speeds up the creation of complex models in Excel. By integrating trusted third-party data from Visible Alpha directly into the model creation process, Monarq removes the need for manual data entry. This saves you time and reduces errors, enabling you to focus on higher-value analysis and decision-making. You can also customize revenue parameters and other assumptions to ensure your models are tailored to your business needs.
            </p>
          </div>
          <div className="faq-item">
            <h4>2. Can I customize revenue assumptions in my models?</h4>
            <p>
              Yes! Monarq allows you to define and customize key, company-specific revenue parameters used to generate the model. This flexibility ensures your financial models reflect the unique conditions of your business, leading to more accurate and tailored forecasts.
            </p>
          </div>
          <div className="faq-item">
            <h4>3. How does Monarq make running financial models faster?</h4>
            <p>
              Monarq significantly accelerates model creation by reducing the time spent on manual data entry and model maintenance, allowing analysts to focus on high-value activities like strategic analysis and decision-making.
            </p>
          </div>
          <div className="faq-item">
            <h4>4. How does Monarq keep my financial models secure?</h4>
            <p>
              Monarq takes data security seriously. We do not store any customer data on our servers. All data accessed via the platform is securely retrieved and automatically populated into your models, without any persistence of user-specific data.
            </p>
          </div>
          <div className="faq-item">
            <h4>5. Do you store any of my data?</h4>
            <p>
              No, Monarq does not store any customer data. Our platform acts as a data transformer and conduit with no storage of user data or credentials.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
