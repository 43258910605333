import React, { useState, useContext } from 'react';
import './Login.css'; // Import the CSS file for styling
import axios from 'axios'; // Import axios for HTTP requests
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext';
import logo from './monarq/logo.png';
import './Navbar.css';
import Navbar from './Navbar';

interface LoginProps {
  loading: boolean; // Add this prop
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Login: React.FC<LoginProps> = ({ loading, setLoading }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // State for error message

  const userContext = useContext(UserContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null); // Clear previous errors
    setLoggingIn(true);
    setLoading(true)

    const credentials = `${username}:${password}`;
    const encodedCredentials = btoa(credentials);

    try {
      const response = await axios.get('https://ezhfngwsam.us-east-2.awsapprunner.com/login', {
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      });
      const isAdmin = response.data.role === "ADMIN" ? true : false
      const modelGroup = response.data.modelGroup
      const token = response.data.token
      if (userContext?.login) {
        userContext.login(response.data.token, isAdmin);
      }
      navigate('/dashboard');
      
    } catch (error) {
      console.log("error: ", error)
      setLoading(false);
      setLoggingIn(false);
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  const clearError = () => {
    setError(null); // Clear the error message when the user focuses on an input
  };

  return (
    <div className="login-root">
      <div className="login-container">
        <div className='login-nav-container'>
          <div className="navbar-admin-wrapper">
            <Navbar/>
          </div>
        </div>
        <div className="login-form">
          <h1>Sign In</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onFocus={clearError}
                required
              />
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={clearError}
                required
              />
            </div>
            <button type="submit">{loggingIn ? `Please Wait...` : `Login`}</button>
            <div className={`error-container ${error ? 'show' : ''}`}>
              {error}
            </div>
          </form>
        </div>
        <div className="login-image">
          {/* Replace this with an image or graphic */}
        </div>
      </div>
    </div>
  );
};

export default Login;
