import React, { useEffect, useState } from 'react';
import { FiUsers, FiChevronsLeft, FiChevronsRight, FiUser } from 'react-icons/fi';
import axios from 'axios';
import { loadUserData } from './AuthCache';
import './Admin.css';
import Navbar from './Navbar';

interface User {
  user_id: string;
  email: string;
  role: string;
  token: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  modelGroup: string;
  userId: string;
}

const Admin: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCreateUser = () => {
    console.log("Open user creation modal or navigate to form.");
    // Example: Open a modal or redirect to the user creation page
  };

  const fetchUsers = async (token: string): Promise<User[]> => {
    try {
      const response = await axios.get<{ users: User[] }>(
        'https://ezhfngwsam.us-east-2.awsapprunner.com/admin/users',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.users;
    } catch (error) {
      console.log('Error fetching users: ', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      setError('Failed to fetch users. Please try again.');
      throw new Error('Failed to fetch users');
    }
  };

  useEffect(() => {
    const { token, validCachedToken } = loadUserData();

    if (validCachedToken) {
      fetchUsers(token!).then(users => {
        setUsers(users);
        setFilteredUsers(users);
      }).catch(console.error);
    } else {
      setError('Invalid token. Please log in again.');
    }
  }, []);

  useEffect(() => {
    const filtered = users.filter(user => 
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);

  return (
    <div className="admin-wrapper">
      <div className="navbar-admin-wrapper">
          <Navbar/>
        </div>
      <main className="admin-main">
        <header className="admin-header">
          <div className="header-content">
            <h1>User Management</h1>
            <button className="create-user-btn" onClick={handleCreateUser}>
              Create User
            </button>
          </div>
          <div className="user-count">Total Users: {users.length}</div>
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
        </header>
        <section className="user-table">
          <div className="table-header">
            <div className="table-cell">User</div>
            <div className="table-cell">Role</div>
            <div className="table-cell">Model Group</div>
            <div className="table-cell">Created</div>
            <div className="table-cell">Last Active</div>
          </div>
          {filteredUsers.map((user: User) => (
            <div className="table-row" key={user.user_id}>
              <div className="table-cell user-info">
                <span className="user-picture"><FiUser /></span>
                {/* <img src="/path/to/default-image.png" alt="User" className="user-picture" /> */}
                <div>
                  <div className="user-name">{user.email.split('@')[0]}</div>
                  <div className="user-email">{user.email}</div>
                </div>
              </div>
              <div className="table-cell">{user.role}</div>
              <div className="table-cell">{user.modelGroup}</div>
              <div className="table-cell">{new Date(user.createdAt).toLocaleString()}</div>
              <div className="table-cell">{new Date(user.updatedAt).toLocaleString()}</div>

              {/* <div className="table-cell actions">
                <button className="action-button edit">
                  <FiEdit />
                </button>
                <button className="action-button delete">
                  <FiTrash />
                </button>
              </div> */}
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

export default Admin;
