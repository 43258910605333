import React, { createContext, useState, ReactNode } from 'react';

interface User {
  isAuthenticated: boolean;
  isAdmin: boolean;
  token: string | null;
}

interface UserContextType {
  user: User;
  login: (token: string, isAdmin: boolean) => void;
  logout: () => void;
}

export const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User>({
    isAuthenticated: false,
    isAdmin: false,
    token: null,
  });

  const login = (token: string, isAdmin: boolean) => {
    setUser({
      isAuthenticated: true,
      isAdmin,
      token,
    });
  };

  const logout = () => {
    setUser({
      isAuthenticated: false,
      isAdmin: false,
      token: null,
    });
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
