import React, { ReactNode } from 'react';
import ModelGenerator from './ModelGenerator';
import Navbar from './Navbar';

interface DashboardRootProps {
  children?: ReactNode;
  loading: boolean; // Add this prop
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardRoot: React.FC<DashboardRootProps> = ({ children, loading, setLoading }) => {
  return (
    <div>
      <div className="dashboardRoot">
        <div className="containerWrapper">
          <ModelGenerator loading={loading} setLoading={setLoading}/>
        </div>
      </div>
      <Navbar/>
    </div>
  );
};

export default DashboardRoot;
