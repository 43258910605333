import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import DashboardRoot from './DashboardRoot';
import Admin from './Admin';
import { loadUserData } from './AuthCache';

// Type definition for AdminRoute
interface AdminRouteProps {
  element: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
  const { token, isAdmin, validCachedToken } = loadUserData();

  if (validCachedToken && isAdmin && token) {
    return <>{element}</>;
  } else {
    return <Navigate to="/login" />;
  }
};

// Type definition for AppRouter
const AppRouter: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login loading={loading} setLoading={setLoading} />} />
        <Route path="/logout" element={<Login loading={loading} setLoading={setLoading} />} />
        <Route path="/" element={<DashboardRoot loading={loading} setLoading={setLoading}>Home Content</DashboardRoot>} />
        <Route path="/dashboard" element={<DashboardRoot loading={loading} setLoading={setLoading}>Dashboard Content</DashboardRoot>} />
        <Route path="/admin" element={<AdminRoute element={<Admin />} />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
