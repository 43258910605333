// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --primary-color: #5ad4ff;  /* var(--primary-color) */
    --secondary-color: #37536c; /* var(--secondary-color) */
    --background-color: #111920; /* var(--background-color) */
    --secondary-background-color: #202e39; /* var(--secondary-background-color) */
    --tertiary-background-color: #131f28; 
    --text-color: white; /* var(--text-color) */
    --secondary-text-color: #c1c3d1; /* var(--secondary-text-color) */
    --highlight-color: #5ad4ff; /* var(--highlight-color) */
    --secondary-highlight-color: #374552; /* var(--secondary-highlight-color) */
    --spinner-color: #f3f3f3;
    --disabled-color: #4f5e6b; /* var(--disabled-color) */
    --success-color: #009255; /* var(--success-color) */
    --bg-positive: rgba(105, 246, 188, 0.8); /* var(--bg-positive) */
    --error-color: #c4003b; /* var(--error-color) */
    --hover-color: #0089e1; /* var(--hover-color) */
}`, "",{"version":3,"sources":["webpack://./src/variables.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,GAAG,yBAAyB;IACpD,0BAA0B,EAAE,2BAA2B;IACvD,2BAA2B,EAAE,4BAA4B;IACzD,qCAAqC,EAAE,sCAAsC;IAC7E,oCAAoC;IACpC,mBAAmB,EAAE,sBAAsB;IAC3C,+BAA+B,EAAE,gCAAgC;IACjE,0BAA0B,EAAE,2BAA2B;IACvD,oCAAoC,EAAE,qCAAqC;IAC3E,wBAAwB;IACxB,yBAAyB,EAAE,0BAA0B;IACrD,wBAAwB,EAAE,yBAAyB;IACnD,uCAAuC,EAAE,uBAAuB;IAChE,sBAAsB,EAAE,uBAAuB;IAC/C,sBAAsB,EAAE,uBAAuB;AACnD","sourcesContent":[":root {\n    --primary-color: #5ad4ff;  /* var(--primary-color) */\n    --secondary-color: #37536c; /* var(--secondary-color) */\n    --background-color: #111920; /* var(--background-color) */\n    --secondary-background-color: #202e39; /* var(--secondary-background-color) */\n    --tertiary-background-color: #131f28; \n    --text-color: white; /* var(--text-color) */\n    --secondary-text-color: #c1c3d1; /* var(--secondary-text-color) */\n    --highlight-color: #5ad4ff; /* var(--highlight-color) */\n    --secondary-highlight-color: #374552; /* var(--secondary-highlight-color) */\n    --spinner-color: #f3f3f3;\n    --disabled-color: #4f5e6b; /* var(--disabled-color) */\n    --success-color: #009255; /* var(--success-color) */\n    --bg-positive: rgba(105, 246, 188, 0.8); /* var(--bg-positive) */\n    --error-color: #c4003b; /* var(--error-color) */\n    --hover-color: #0089e1; /* var(--hover-color) */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
