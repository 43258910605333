import React, { useRef } from "react";
import ReactSelect from "react-select";

export const MultiSelect = (props) => {
  const valueRef = useRef(props.value);
  valueRef.current = props.value;

  const selectAllOption = {
    value: "<SELECT_ALL>",
    label: "Select All Parameters",
  };

  const isSelectAllSelected = () => {
    // Return true if all options are selected
    return valueRef.current.length === props.multiSelectOptions.length;
  }

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    (isSelectAllSelected() && option.value !== selectAllOption.value);

  const getOptions = () => {
    const newOptions = [selectAllOption, ...props.multiSelectOptions];
    return newOptions;
  };

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption] : props.value;

  const onChangeMultiSelect = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta;
    props.handleRevenueParameterChange(newValue);

    if (action === "select-option") {
      if (option.value === selectAllOption.value) {
        // If "Select All" is selected, set all options
        props.handleRevenueParameterChange(props.multiSelectOptions, actionMeta);
        props.onMenuClose();
      } else {
        // Handle individual option selection
        props.handleRevenueParameterChange(newValue || [], actionMeta);
      }
    } else if (action === "deselect-option") {
      if (option.value === selectAllOption.value) {
        // If "Select All" is deselected, clear all selections
        props.handleRevenueParameterChange([], actionMeta);
      } 
      else {
        // Handle individual option deselection
        // Check if all options were selected before deselection
        if (isSelectAllSelected()) {
          // If all options were selected and one is being deselected, remove "Select All"
          const filteredOptions = newValue.filter(({ value }) => value !== option.value);
          props.handleRevenueParameterChange(filteredOptions, actionMeta);
        } else {
          // Update value normally
          props.handleRevenueParameterChange(newValue || [], actionMeta);
        }
      }
    }
  };

  return (
    <ReactSelect
      isOptionSelected={isOptionSelected}
      options={getOptions()} // Include Select All option
      value={getValue()} // Ensure value logic is correct
      onChange={onChangeMultiSelect}
      hideSelectedOptions={true}
      closeMenuOnSelect={false} // Keep menu open
      maxMenuHeight={200}
      placeholder="Select Symbol or Start Typing..."
      isMulti
      {...props}
    />
  );
};
